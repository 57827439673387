// -------------------------
// --- You can add your custom CSS here and it will overwrite template styles.
// -------------------------
.ellips{
  text-overflow: ellipsis;  
  overflow: hidden;
  white-space: nowrap;
}
.actions1{
  padding: 10px;
  margin: 10px;
}