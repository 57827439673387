/*
// base
*/
@import '../variables';
@import '../mixins/direction';
@import 'node_modules/react-modal-video/scss/modal-video.scss';

@import "node_modules/react-image-gallery/styles/scss/image-gallery.scss";







html {
    height: 100%;
}

iframe {
    z-index: 0 !important;
    position: initial !important;
    //display: none !important;

}
.modal-video-movie-wrap iframe {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
body {
    height: 100%;
    font-family: $body-font-family;
    font-size: $body-font-size;
    font-weight: $body-font-weight;
    line-height: $body-line-height;
    background: $body-bg;
    color: $body-font-color;
    backface-visibility: hidden;
    overflow-y: scroll;

    @include direction {
        text-align: $inline-start;
        direction: $direction;
    }
}


#root {
    height: 100%;
}


svg {
    display: block;
}


a {
    color: $link-color;
}
a:hover {
    color: $link-hover-color;
    text-decoration: none;
}


label {
    margin-bottom: .25rem;
}


h1 {
    @if ($h1-font-family != null) { font-family: $h1-font-family; }
    @if ($h1-font-size != null)   { font-size: $h1-font-size; }
    @if ($h1-font-weight != null) { font-weight: $h1-font-weight; }
    @if ($h1-font-style != null)  { font-style: $h1-font-style; }
}
h2 {
    @if ($h2-font-family != null) { font-family: $h2-font-family; }
    @if ($h2-font-size != null)   { font-size: $h2-font-size; }
    @if ($h2-font-weight != null) { font-weight: $h2-font-weight; }
    @if ($h2-font-style != null)  { font-style: $h2-font-style; }
}
h3 {
    @if ($h3-font-family != null) { font-family: $h3-font-family; }
    @if ($h3-font-size != null)   { font-size: $h3-font-size; }
    @if ($h3-font-weight != null) { font-weight: $h3-font-weight; }
    @if ($h3-font-style != null)  { font-style: $h3-font-style; }
}
h4 {
    @if ($h4-font-family != null) { font-family: $h4-font-family; }
    @if ($h4-font-size != null)   { font-size: $h4-font-size; }
    @if ($h4-font-weight != null) { font-weight: $h4-font-weight; }
    @if ($h4-font-style != null)  { font-style: $h4-font-style; }
}
h5 {
    @if ($h5-font-family != null) { font-family: $h5-font-family; }
    @if ($h5-font-size != null)   { font-size: $h5-font-size; }
    @if ($h5-font-weight != null) { font-weight: $h5-font-weight; }
    @if ($h5-font-style != null)  { font-style: $h5-font-style; }
}
h6 {
    @if ($h6-font-family != null) { font-family: $h6-font-family; }
    @if ($h6-font-size != null)   { font-size: $h6-font-size; }
    @if ($h6-font-weight != null) { font-weight: $h6-font-weight; }
    @if ($h6-font-style != null)  { font-style: $h6-font-style; }
}
img.img-70{
    display: inline;
}img.img-105{
    display: inline;
    border-radius: 75%;
}

.tooltip1 {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; 
  }
  
 
  .tooltip1 .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    margin-bottom: -83px;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
  }
  
  .eminew {
    border: 1px solid #ea4e55;
    border-radius: 0px;
    padding: 6px;
}.tabs-nav.tabs-nav1.fq2.clearfix {
    border-radius: 0px !important;
}
  .tooltip1:hover .tooltiptext {
    visibility: visible;
  }
  hr{
    height: 0px !important;
  }ul.tabs-1.tabs-2.faq4 li {
    display: table-cell !important;
}ul.tabs-1.tabs-2.faq4 li {
    width: 50% !important;
    padding: 13px 12px 2px 7px !important;
}
.cartnew1 {
    margin-right: 5px;
}a.cart-table__product-name1 {
    font-size: 14px;
}ul.tabs-1.tabs-2.faq4 {
    display: table;
}
.emipopupor{
    text-align: center;
    display: inherit;
    margin-bottom: 3px;
}span.popup12.cart-table__product-name1 {
    font-size: 14px;
    text-decoration: none;
}

input#css {
    margin-left: 7px;
}
label {
    font-size: 1rem;
    line-height: 30px;
    color: #063f83;
    font-weight: 600;
}iframe.video {
    display: initial !important;
}.banner-5-txt.pro img {
    width: 105px;
    height: auto;
}.banner-5-txt.pro {
    padding: 15px 7px 4px 8px;
}button.footer-newsletter__form-button.btn.btn-primary.footersub {
    padding: 5px;
}p.paranew {
    font-size: 14px;
    color: #000;
}
.course-data.course-data1{
    background-color: #fff;
    border: 1px solid #eee;
    padding: 8px;    text-align: center;
    border-radius: 4px;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%); 
}
