/*
// .topbar
*/
@import '../variables';
@import '../mixins/direction';


$local-item-margin: 0;


.topbar {
    height: $topbar-height;
    background: #fff;
    box-shadow: $topbar-shadow;
    border-bottom: $topbar-border-bottom;
    font-size: 14px;
    line-height: 14px;
    color: $topbar-font-color;
    position: relative;
    z-index: 20;
}
.topbar__container {
    height: 100%;
}
.topbar__row {
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 #{-$local-item-margin};
}
.topbar__item {
    margin: 0 $local-item-margin;
    height: 100%;
    display: flex;
    align-items: center;
}
.topbar__item-value {
    color: $topbar-dropdown-value-color;
    transition: all .1s;
}
.topbar__spring {
    flex-grow: 1;
}
.topbar__item--link + .topbar__item--link {
    @include direction {
        #{$margin-inline-start}: 16px;
    }
}

.wrapper {
    // width: 330px;
    // font-family: 'Helvetica';
    // font-size: 14px;
    // border: 1px solid #CCC;
  }
  
  .StepProgress {
    position: relative;
    padding-left: 45px;
    list-style: none;
    
    &::before {
      display: inline-block;
      content: '';
      position: absolute;
      top: 0;
      left: 15px;
      width: 10px;
      height: 100%;
      border-left: 2px solid #CCC;
    }
    
    &-item {
      position: relative;
      counter-increment: list;
      
      &:not(:last-child) {
        padding-bottom: 20px;
      }
      
      &::before {
        display: inline-block;
        content: '';
        position: absolute;
        left: -30px;
        height: 100%;
        width: 10px;
      }
      
      &::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: -2px;
        left: -38px;
        width: 18px;
        height: 18px;
        border: 2px solid #CCC;
        border-radius: 50%;
        background-color: #FFF;
      }
      
      &.is-done {
        &::before {
          border-left: 2px solid green;
        }
        &::after {
          content: "✔";
          font-size: 10px;
          color: #FFF;
          text-align: center;
          border: 2px solid green;
          background-color: green;
        }
      }
      
      &.current {
        &::before {
          border-left: 2px solid green;
        }
        
        &::after {
            content: counter(list);
            padding-top: 1px;
            width: 22px;
            height: 22px;
            top: 0px;
            left: -38px;
            font-size: 12px;
            text-align: center;
            color: green;
            border: 2px solid green;
            background-color: white;
        }
      }
    }
    
    strong {
      display: block;
    }
  }